import Footer from '../Footer'
import NavBar from '../NavBar'
import React from 'react'
import fr from '../../locales/fr'
// import SectionContact from '../SectionContact'

const ContactButton: React.FunctionComponent = () => (
  <a
    href={`mailto:${fr.emailContact}`}
    target="_blank"
    className="bg-primary h-16 w-16 rounded-full fixed bottom-6 right-6 flex items-center justify-center"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-8 w-8">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
      />
    </svg>
  </a>
)

const Layout: React.FunctionComponent = ({ children }) => (
  <>
    <NavBar />
    {children}
    {/* <SectionContact /> */}
    <Footer />
    <ContactButton />
  </>
)

export default Layout
