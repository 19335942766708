import Facebook, { FacebookOptions } from './Facebook'
import Twitter, { TwitterOptions } from './Twitter'
import { graphql, useStaticQuery } from 'gatsby'

import Helmet from 'react-helmet'
import React from 'react'

interface SeoProps {
  title?: string
  description?: string
  slug?: string
  noIndex?: boolean
  date?: string
  isBlogPost?: boolean
  lang?: string
  timeToRead?: number
  image?: string
}

export const seoFragment = graphql`
  fragment SeoProps on SiteSiteMetadata {
    siteUrl
    siteName
    lang
    metaTitle
    metaDescription
    ogImage
    creationDate
    facebook
    youtube
    bandcamp
  }
`

export const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        ...SeoProps
      }
    }
  }
`

const Seo: React.FunctionComponent<SeoProps> = props => {
  const data: GatsbyTypes.SEOQuery = useStaticQuery(query)

  const meta = data.site?.siteMetadata as GatsbyTypes.SeoPropsFragment
  const groupId = `#${meta.siteName?.toLocaleLowerCase().replace(' ', '-')}`
  const buildTime = data.site?.buildTime ?? ''
  const title = props.title || meta.metaTitle
  const description = props.description || meta.metaDescription
  const image = `${meta.siteUrl}${props.image ? props.image : '/' + meta.ogImage}`
  const url = `${meta.siteUrl}${props.slug ?? ''}`
  const lang = props.lang || meta.lang
  const socialsLink = [meta.facebook, meta.youtube, meta.bandcamp]
  const twitterOptions: TwitterOptions = {
    title,
    image,
    desc: description,
  }

  const facebookOptions: FacebookOptions = {
    title,
    image,
    desc: description,
    siteName: meta.siteName,
    locale: meta.lang ?? '',
    url: url,
    type: props.isBlogPost ? 'article' : 'website',
  }

  return (
    <>
      <Helmet title={title} htmlAttributes={{ lang: lang }}>
        {props.noIndex && <meta name="robots" content="noindex, follow" />}
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'MusicGroup',
            '@id': groupId,
            name: meta.siteName,
            url: meta.siteUrl,
            sameAs: socialsLink,
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'WebSite',
            name: meta.siteName,
            url: meta.siteUrl,
            publisher: {
              '@type': 'MusicGroup',
              '@id': groupId,
            },
            copyrightHolder: {
              '@type': 'MusicGroup',
              name: groupId,
            },
            copyrightYear: '2019',
            creator: {
              '@type': 'MusicGroup',
              name: groupId,
            },
            inLanguage: lang,
            dateModified: buildTime,
            datePublished: meta.creationDate,
          })}
        </script>
        {!props.isBlogPost && (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'WebPage',
              name: title,
              description: description,
              publisher: {
                '@type': 'MusicGroup',
                '@id': groupId,
              },
              image: image,
              inLanguage: lang,
            })}
          </script>
        )}

        {props.isBlogPost && (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BlogPosting',
              mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': url,
              },
              headline: title,
              description: description,
              image: image,
              author: {
                '@type': 'MusicGroup',
                '@id': groupId,
              },
              publisher: {
                '@type': 'MusicGroup',
                '@id': groupId,
              },
              datePublished: props.date,
              inLanguage: lang,
            })}
          </script>
        )}
      </Helmet>
      <Twitter options={twitterOptions} />
      <Facebook options={facebookOptions} />
    </>
  )
}

export default Seo
