export default {
  groupName: 'Lux Bas-Fonds',
  emailContact: 'luxbasfonds@gmail.com',
  about: 'A propos',
  actualities: 'Actualités',
  contact: 'Contact',
  photos: 'Photos',
  newAlbum: 'Album',
  albumName: 'amnésie internationale ',
  available: 'disponible',
  press: 'Presse',
  concerts: 'Concerts',
  quotes:
    "“ Le Rock'n'Roll est la plus brutale, la plus hideuse, la plus prostrée, la plus vicieuse des formes d'expressions qu'il m'ait malheureusement été donné d'écouter. “",
  quotesAuthor: 'Franck Sinatra',
  aboutDesc1: 'Créé en 2012, ne croit pas en ce qu’il voit mais en ce qu’il ressent, du rock à tous les étages.',
  aboutDesc2:
    'Groupe Rock, poètes libertaires, gardien du bruit, monstre de scène, musique batarde, les textes c’est de la Noblesse Populaire !',
  listenOrBuy: " Ecoutez / Achetez l'album",
  byEmail: 'Envoyez-nous un email',
  legalInfos: 'Informations légales',
  legalMentions: 'Mentions légales',
  readMore: 'Lire la suite',
  learnMore: 'En savoir plus',
  showAllActualities: 'Voir toutes les actualités',
  showAllPresse: 'Voir toutes les chroniques',
  showAllConcerts: 'Voir toutes les dates',
  friends: 'Les amis',
  friendsDescription:
    "Parce que nous serions bien seuls et pas grand chose sans eux, nous vous présentons ici nos amis et partenaires de confiance. Ceux qui s'investissent dans notre quotidien par leur savoir-faire, leur expertise et poussent encore plus loin le champ des possibles.",
  createdBy: 'Crée par tmocellin',
  copyRight: '© 2021 lux bas-fonds. Tous droits réservés.',
  notFound: 'Page non trouvée',
  notFoundTitle: 'Vous êtes perdu !!',
  notFoundDescription: "La page n'existe plus ou n'a jamais existé.",
  actualityMetaDesc: "Suivez l'ensemble de l'actualité du groupe.",
  pressMetaDesc: "Retrouvez l'ensemble des chroniques de presse.",
  concertsMetaDesc: "Découvrez l'ensemble de nos dates de concert afin de n’en rater aucune.",
  photosMetaDesc: 'Revivez les meilleurs moments des concerts et découvrez les coulisses en photos.',
  /* * *
   *
   *
   * Groups members informations
   *
   *
   * * */
  members: {
    geraud: {
      name: 'Géraud Bastar',
      role: 'Chanteur',
      quote: '“ Vous zavez pas trouvé mes calmants ? “',
      description:
        'A:rès 112 K7, 2 Vinyls et 5 CD de commis, plus de 1 million de Kms parcourus avec Barons du Délire, on peut dire qu’Avec Géraud, y’a que ses pneus qui sont lisses….',
    },
    julien: {
      name: 'Julien',
      role: 'Batteur',
      quote: "“Le rock, c'est un monde à part...“ ",
      description: '30 ans de musique avec Indian Child, Bad Burning Blues, Acdçu...',
    },
    lezard: {
      name: 'Lézard',
      role: 'Guitariste',
      quote: '“ Je n’ai jamais cherché à être bizarre ... “',
      description:
        '35 ans de guitare à son actif. Il joue avec John Brassett et collabore avec Tyla (Dogs d’Amour), rejoint LBF à sa création.',
    },
    pat: {
      name: 'Pat le Black',
      role: 'Bassiste',
      quote: '“ Si c’est trop fort c’est que vous êtes trop vieux “',
      description:
        "Y a un camion avec des pneus lisses du matos et des gonzs bizarres qui passait par là en 2018, j'suis monté dedans ....",
    },
  },
}
