import Img, { FixedObject } from 'gatsby-image'
import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'

import clsx from 'clsx'
import fr from '../../locales/fr'
import routes from '../../routes'

const navLinks = [
  {
    label: fr.actualities,
    to: routes.actualities,
  },
  {
    label: fr.press,
    to: routes.press,
  },
  {
    label: fr.concerts,
    to: routes.concerts,
  },
  {
    label: fr.photos,
    to: routes.photos,
  },
  {
    label: fr.friends,
    to: routes.friends,
  },
  {
    label: `${fr.newAlbum} ${fr.available}`,
    to: routes.newAlbum,
  },
]

const classes = /** class={ */ {
  navbar: 'max-w-7xl mx-auto px-2 lg:px-8',
  navContainer: 'relative flex items-center justify-between h-16',
  menuBtnWrapper: 'absolute inset-y-0 left-0 flex items-center sm:hidden',
  menuBtn:
    'inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white',
  navLink: 'hover:text-primary px-1 py-2 rounded-md text-sm lg:text-base font-display',
  navLinkMobile: 'hover:text-primary block px-3 py-2 rounded-md text-base font-display',
  brandWrapper: 'flex-1 flex items-center justify-center sm:items-stretch sm:justify-start',
  brand: 'flex-shrink-0 flex items-center',
  navLinksContainer: 'absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0',
  navLinksWrapper: 'hidden sm:block sm:ml-6',
  navLinks: 'flex space-x-4',

  navLinksMobile: 'px-2 pt-2 pb-3 space-y-1',
} /** } */

const NavBar: React.FunctionComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const data = useStaticQuery<GatsbyTypes.NavBrandQueryQuery>(graphql`
    query NavBrandQuery {
      fileName: file(relativePath: { eq: "navbrand.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <nav className="bg-black">
      <div className={classes.navbar}>
        <div className={classes.navContainer}>
          <div className={classes.menuBtnWrapper}>
            {/* <!-- Mobile menu button--> */}
            <button className={classes.menuBtn} aria-expanded="false" onClick={(): void => setMenuOpen(!menuOpen)}>
              <span className="sr-only">Ouvrir le menu principale</span>
              {!menuOpen ? (
                <svg
                  className="block w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
          <div className={classes.brandWrapper}>
            <div className={classes.brand}>
              <Link to={routes.home}>
                <Img
                  className="w-20"
                  fixed={data.fileName?.childImageSharp?.fixed as FixedObject}
                  alt={'Lux bas-fond logo'}
                />
              </Link>
            </div>
          </div>
          <div className={classes.navLinksContainer}>
            <div className={classes.navLinksWrapper}>
              <div className={classes.navLinks}>
                {navLinks.map(navLink => (
                  <Link
                    className={clsx([
                      classes.navLink,
                      navLink.label === `${fr.newAlbum} ${fr.available}` && 'text-primary',
                    ])}
                    to={navLink.to}
                    key={navLink.to}
                  >
                    {navLink.label}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(['sm:hidden', menuOpen ? 'block' : 'hidden'])}>
        <div className={classes.navLinksMobile}>
          {navLinks.map(navLink => (
            <Link
              className={clsx([
                classes.navLinkMobile,
                navLink.label === `${fr.newAlbum} ${fr.available}` && 'text-primary',
              ])}
              to={navLink.to}
              key={navLink.to}
            >
              {navLink.label}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  )
}

export default NavBar
