import Helmet from 'react-helmet'
import React from 'react'

export interface FacebookOptions {
  url: string
  locale: string
  type?: string
  title: string
  desc: string
  image: string
  siteName?: string
}

interface FacebookProps {
  options: FacebookOptions
}

const Facebook: React.FunctionComponent<FacebookProps> = ({ options }: FacebookProps) => {
  return (
    <Helmet>
      {options.siteName && <meta property="og:site_name" content={options.siteName} />}
      <meta property="og:locale" content={options.locale} />
      <meta property="og:url" content={options.url} />
      <meta property="og:type" content={options.type} />
      <meta property="og:title" content={options.title} />
      <meta property="og:description" content={options.desc} />
      <meta property="og:image" content={options.image} />
      <meta property="og:image:alt" content={options.desc} />
    </Helmet>
  )
}

export default Facebook
