export default {
  home: '/',
  photos: '/photos',
  actualities: '/actualites',
  press: '/presse',
  concerts: '/concerts',
  newAlbum: '/#album',
  friends: '/les-amis',
  legal: '/mentions-legales',
  // external url
  bandcamp: 'https://luxbasfonds.bandcamp.com/',
  tmocellin: 'https://tmocellin.com',
  facebookPage: 'https://www.facebook.com/geraudbastarluxbasfonds/',
  youtubeChannel: 'https://www.youtube.com/channel/UCIZ61DK5klKhkuI_zwOWUuA',
}
