import Helmet from 'react-helmet'
import React from 'react'

export interface TwitterOptions {
  type?: string
  username?: string
  title: string
  desc: string
  image: string
}

interface TwitterProps {
  options: TwitterOptions
}

const Twitter: React.FunctionComponent<TwitterProps> = ({ options }: TwitterProps) => (
  <Helmet>
    {options.username && <meta name="twitter:creator" content={options.username} />}
    <meta name="twitter:card" content={options.type || 'summary_large_image'} />
    <meta name="twitter:title" content={options.title} />
    <meta name="twitter:description" content={options.desc} />
    <meta name="twitter:image" content={options.image} />
    <meta name="twitter:image:alt" content={options.desc} />
  </Helmet>
)

export default Twitter
