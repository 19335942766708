import { Link } from 'gatsby'
import React from 'react'
import fr from '../../locales/fr'
import routes from '../../routes'

const classes = /** class={ */ {
  container: 'max-w-5xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8 border-t border-gray-700',
  links: '-mx-5 -my-2 flex flex-wrap justify-center',
  linkWrapper: 'px-5 py-2',
  link: 'text-base text-gray-500 hover:text-gray-400',
  icons: 'mt-8 flex justify-center space-x-6',
  iconLink: 'text-gray-400 hover:text-primary',
  icon: 'h-8 w-8',
  copyRight: 'mt-8 text-center text-base text-gray-400',
} /** } */

const Footer: React.FunctionComponent = () => (
  <footer>
    <div className={classes.container}>
      <nav className={classes.links} aria-label="Footer">
        <div className={classes.linkWrapper}>
          <Link to={routes.legal} className={classes.link}>
            {fr.legalMentions}
          </Link>
        </div>

        <div className={classes.linkWrapper}>
          <a href={`${routes.tmocellin}?utm_source=LuxBasFonds&utm_campaign=footer`} className={classes.link}>
            {fr.createdBy}
          </a>
        </div>
      </nav>
      <div className={classes.icons}>
        <a href={routes.facebookPage} target="blank" rel="nofollow" className={classes.iconLink}>
          <span className="sr-only">Facebook</span>
          <svg className={classes.icon} fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        </a>

        <a href={routes.youtubeChannel} target="blank" rel="nofollow" className={classes.iconLink}>
          <span className="sr-only">Youtube</span>
          <svg xmlns="http://www.w3.org/2000/svg" className={classes.icon} role="img" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
            />
          </svg>
        </a>
      </div>
      <p className={classes.copyRight}>{fr.copyRight}</p>
    </div>
  </footer>
)

export default Footer
